import Dropdown from "components/Dropdown";
import SmartSearch from "components/SmartSearch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchDropdown from "components/SearchDropdown";
import CONSTANTS from "../../common/constants";
import SelectDomesticInternational from "../SelectDomesticInternational";
import { SearchFilterWrapper } from "./styled";
import {
    setProgramCategoryId,
    setSearchKey,
    setCountryCode,
    setStateCode,
    setSortOrganizations,
} from "./searchFilter.slice";

interface Props {
    placeholder: string;
    programCategories?: any;
    programCategory?: any;
    setProgramCategory?: any;
    setDefaultPagination: any;
    country?: any;
    setCountry?: any;
    stateCd: any;
    setStateCd: any;
    tabName?: string;
    setClearFilters: any;
    countryDropdown?: any;
    stateDropdown?: any;
    isCountryLoading?: boolean;
    isStateLoading?: boolean;
    getFacilitiesResult: any;
    emsInd?: boolean;
    setSortInd?: any;
}

const SearchFilter = ({
    placeholder,
    programCategories,
    programCategory,
    setProgramCategory,
    setDefaultPagination,
    country,
    setCountry,
    stateCd,
    setStateCd,
    tabName,
    setClearFilters,
    countryDropdown,
    stateDropdown,
    isCountryLoading,
    isStateLoading,
    getFacilitiesResult,
    emsInd,
    setSortInd,
}: Props) => {
    const dispatch = useDispatch();
    const [programsDisabled, setProgramsDisabled] = useState<boolean>(false);
    const region = useSelector((state: any) => {
        return state.region;
    });
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    const [clearFilterInd, setClearFilterInd] = useState<boolean>(false);
    const [mounted, setMounted] = useState<boolean>(false);
    const [searchTimer, setSearchTimer] = useState<any>();

    useEffect(() => {
        if (mounted) {
            setCountry(CONSTANTS.ALL);
            setStateCd(CONSTANTS.ALL);
            dispatch(setCountryCode(CONSTANTS.ALL));
            dispatch(setStateCode(CONSTANTS.ALL));
        }
        setMounted(true);
    }, [region.region]);

    useEffect(() => {
        if (programCategories?.length > 0) {
            setProgramsDisabled(false);
        } else {
            setProgramsDisabled(true);
        }
    }, [programCategories]);

    const changeSearchKey = (value: any) => {
        setDefaultPagination();
        dispatch(setSearchKey(value));
    };

    const changeProgramCategory = (value: any, clearFilter?: boolean) => {
        if (!clearFilter) {
            getFacilitiesResult({ categoryId: value });
        }
        setProgramCategory(value);
        if (searchFilter.programCategory !== value) {
            setDefaultPagination();
            dispatch(setProgramCategoryId(value));
        }
    };

    const changeCountry = (value: any, clearFilter?: boolean) => {
        if (!clearFilter) {
            getFacilitiesResult({ countryCd: value });
        }
        setCountry(value);
        if (searchFilter.countryCode !== value) {
            setDefaultPagination();
            dispatch(setCountryCode(value));
        }
    };

    const changeState = (value: any, clearFilter?: boolean) => {
        if (!clearFilter) {
            getFacilitiesResult({ stateCode: value });
        }
        setStateCd(value);
        if (searchFilter.stateCode !== value) {
            setDefaultPagination();
            dispatch(setStateCode(value));
        }
    };

    const clearAllFilters = () => {
        setClearFilters(true);
        if (programCategory && programCategory !== CONSTANTS.ALL) {
            changeProgramCategory(CONSTANTS.ALL, true);
        }
        if (
            (region.region === CONSTANTS.DOMESTIC || emsInd) &&
            stateCd !== CONSTANTS.ALL
        ) {
            changeState(CONSTANTS.ALL, true);
        }
        if (
            region.region === CONSTANTS.INTERNATIONAL &&
            country !== CONSTANTS.ALL
        ) {
            changeCountry(CONSTANTS.ALL, true);
        }
        if (region.region !== CONSTANTS.ALL) {
            setClearFilterInd(!clearFilterInd);
        }
        if (searchFilter.sortOrganizations !== CONSTANTS.ALL) {
            setClearFilterInd(!clearFilterInd);
            setSortInd(undefined);
            dispatch(setSortOrganizations(CONSTANTS.ALL));
        }
        if (searchFilter.searchKey) {
            clearTimeout(searchTimer);
            changeSearchKey("");
        }
        getFacilitiesResult({
            regionName: CONSTANTS.ALL,
            categoryId: CONSTANTS.ALL,
            countryCd: CONSTANTS.ALL,
            stateCode: CONSTANTS.ALL,
            search: "",
        });
    };

    const getSearchedItemsTable = (value: any) => {
        getFacilitiesResult({ search: value });
    };

    return (
        <SearchFilterWrapper
            className="search-filter-block"
            role="group"
            aria-label="filters"
        >
            <div className="search-filter-field">
                <div className="search-filter search-filter-item">
                    <label htmlFor={`searchFilter-${tabName}`}>Search</label>
                    <SmartSearch
                        searchKey={searchFilter.searchKey}
                        setSearchKey={changeSearchKey}
                        getSearchedItems={getSearchedItemsTable}
                        arialabel=""
                        id={`searchFilter-${tabName}`}
                        placeholder={placeholder}
                        setSearchTimer={setSearchTimer}
                    />
                </div>
                {!emsInd && (
                    <div className="location-filter search-filter-item">
                        <label
                            htmlFor={`locationFilter-${tabName}`}
                            id={`locationFilter-${tabName}-label`}
                        >
                            Region
                        </label>
                        <SelectDomesticInternational
                            clearFilterInd={clearFilterInd}
                            setDefaultPagination={setDefaultPagination}
                            id={`locationFilter-${tabName}`}
                            getFacilitiesResult={getFacilitiesResult}
                        />
                    </div>
                )}
                {(region.region === CONSTANTS.DOMESTIC || emsInd) && (
                    <div className="state-filter search-filter-item">
                        <label
                            htmlFor={`stateFilter-${tabName}`}
                            id={`stateFilter-${tabName}-label`}
                        >
                            State
                        </label>
                        <SearchDropdown
                            id={`stateFilter-${tabName}`}
                            items={stateDropdown}
                            selectedValue={stateCd}
                            callParentOnSelect={(value: any) =>
                                changeState(value)
                            }
                            disabled={isStateLoading}
                            isLoading={isStateLoading}
                            placeholder="Select"
                            label="State"
                        />
                    </div>
                )}
                {region.region === CONSTANTS.INTERNATIONAL && (
                    <div className="state-filter search-filter-item">
                        <label
                            htmlFor={`countryFilter-${tabName}`}
                            id={`countryFilter-${tabName}-label`}
                        >
                            Location
                        </label>
                        <SearchDropdown
                            id={`countryFilter-${tabName}`}
                            items={countryDropdown.filter(
                                (countryItem: any) =>
                                    countryItem.label !=
                                    CONSTANTS.DOMESTIC_COUNTRY_NAME
                            )}
                            selectedValue={country}
                            callParentOnSelect={(value: any) =>
                                changeCountry(value)
                            }
                            disabled={isCountryLoading}
                            isLoading={isCountryLoading}
                            placeholder="Select"
                            label="Location"
                        />
                    </div>
                )}
                {region.region === CONSTANTS.ALL && !emsInd && (
                    <div className="state-filter search-filter-item">
                        <label
                            htmlFor={`stateOrCountryFilter-${tabName}`}
                            id={`stateOrCountryFilter-${tabName}-label`}
                        >
                            State/Location
                        </label>
                        <Dropdown
                            id={`stateOrCountryFilter-${tabName}`}
                            items={[{ label: "All", value: CONSTANTS.ALL }]}
                            selectedValue={CONSTANTS.ALL}
                            callParentOnSelect={{}}
                        />
                    </div>
                )}
                {!emsInd && (
                    <div className="program-filter search-filter-item">
                        <label
                            htmlFor={`programTypeFilter-${tabName}`}
                            id={`programTypeFilter-${tabName}-label`}
                        >
                            Program Type
                        </label>
                        <SearchDropdown
                            id={`programTypeFilter-${tabName}`}
                            items={programCategories}
                            selectedValue={programCategory}
                            callParentOnSelect={(value: any) =>
                                changeProgramCategory(value)
                            }
                            isLoading={programsDisabled}
                            disabled={programsDisabled}
                            placeholder="Select"
                            label="Program Type"
                        />
                    </div>
                )}
            </div>
            {(searchFilter.searchKey ||
                (region.region && region.region !== CONSTANTS.ALL) ||
                (searchFilter.sortOrganizations &&
                    searchFilter.sortOrganizations !== CONSTANTS.ALL) ||
                (country && country !== CONSTANTS.ALL) ||
                (stateCd && stateCd !== CONSTANTS.ALL) ||
                (programCategory && programCategory !== CONSTANTS.ALL)) && (
                <div className="d-flex flex-row-reverse mt-n1 mt-sm-n2 mt-xl-2 mb-n3">
                    <button
                        className="btn-text-link-uline font-14"
                        onClick={clearAllFilters}
                    >
                        <i className="aha-icon-close mr-1" />
                        Clear Filter
                    </button>
                </div>
            )}
        </SearchFilterWrapper>
    );
};

export default SearchFilter;
