import React, { useEffect, useState } from "react";
import { HospitalBannerWrapper } from "./styled";
import "../../styles/styles.scss";
import CONSTANTS from "common/constants";
import moment from "moment-timezone";
import { approveHospital, deactivateHospital } from "api/adminDashboardAPI";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { showToast } from "components/Toast/toast.slice";
import FocusTrap from "focus-trap-react";
import {
    parseJwt,
    wordSplit,
    showModal,
    localDateToYYYYMMDDFormatString,
} from "../../../common/utils";
import { unmapFacilityFromCorporation } from "../../../api/corporationAPI";

interface Props {
    hospital: any;
    programData: any;
    lastEditedData: any;
    exportFacilityDetailsData: any;
    certificateData?: any;
    close: any;
    isCorporation?: any;
    activeMenu?: any;
    orderId?: any;
    isAdmin?: any;
    isEmsAdmin?: any;
    isExport?: any;
    facilityId?: any;
    id?: any;
    confirmDeactivateModalId?: any;
    confirmRemoveModalId?: any;
    detailsTitleBannerId?: any;
}

export const FacilityDetailsBanner = ({
    hospital,
    programData,
    lastEditedData,
    exportFacilityDetailsData,
    certificateData,
    close,
    isCorporation,
    activeMenu,
    orderId,
    isAdmin,
    isEmsAdmin,
    isExport,
    facilityId,
    id,
    confirmDeactivateModalId,
    confirmRemoveModalId,
    detailsTitleBannerId,
}: Props) => {
    const [lastEditedInfo, setLastEditedInfo] = useState<any>();
    const [prevUpdate, setPrevUpdate] = useState<any>("");
    const corporationId = parseJwt(
        window.localStorage.getItem("userAccessToken")
    ).corporation_id;
    const dispatch = useDispatch();
    const params: any = useParams();
    const [deactivateFacilityActivateInd, setDeactivateFacilityActivateInd] =
        useState<boolean>(false);
    const [removeFacilityActivateInd, setRemoveFacilityActivateInd] =
        useState<boolean>(false);
    const [activateFacilityActivateInd, setActivateFacilityActivateInd] =
        useState<boolean>(false);

    useEffect(() => {
        if (lastEditedData) {
            const currentData = lastEditedData;
            const dateComparisonRes = lastEditedInfo
                ? new Date(currentData.updatedAt).getTime() >
                  new Date(prevUpdate).getTime()
                : true;

            if (dateComparisonRes) {
                const timeDetails: any = `${moment
                    .utc(currentData.updatedAt)
                    .local()
                    .format("hh:mm:ss A")}`;
                const lastEdited = `${currentData.updatedBy?.firstName} ${
                    currentData.updatedBy?.lastName
                }, ${localDateToYYYYMMDDFormatString(
                    currentData.updatedAt
                )} ${timeDetails}`;
                setPrevUpdate(currentData.updatedAt);
                setLastEditedInfo(lastEdited);
            }
        }
    }, [lastEditedData]);

    const deactivateFacility = async () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const deactivateResult = await deactivateHospital(
            hospital.facilityCode,
            false
        );
        if (deactivateResult.success) {
            const toast = {
                message: `${hospital.facilityName} has been deactivated.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            close();
            deactivateFacilityConfirmationDismissModal();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
    };

    const unmapFacility = async () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        unmapFacilityFromCorporation(corporationId, hospital.hospitalId)
            .then((data: any) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (data.success == true) {
                    dispatch(
                        showToast({
                            visibility: true,
                            message: `${hospital.facilityName} removed from the Corporation successfully.`,
                            type: "success",
                            code: "Success:",
                        })
                    );
                    close();
                    removeFacilityConfirmationDismissModal();
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    const approveFacility = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        approveHospital(
            hospital.hospitalId,
            params.programId,
            params.categoryId,
            "APPROVED"
        )
            .then((response) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (response.success && response.statusCode === 200) {
                    dispatch(
                        showToast({
                            visibility: true,
                            title: "Organization Approved:",
                            message: `Organization ${hospital.facilityName} successfully approved for the ${programData.programCategoryName} program.`,
                            type: "success",
                        })
                    );
                    close();
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    const activateFacility = async () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const activateResult = await deactivateHospital(
            hospital.facilityCode,
            true,
            hospital.hospitalId,
            null,
            parseInt(params.categoryId, 10)
        );
        if (activateResult.success) {
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
            const toast = {
                message: `${hospital.facilityName} successfully activated.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            close();
            activateFacilityConfirmationDismissModal();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
    };
    const deactivateFacilityConfirmationPopupModal = () => {
        setDeactivateFacilityActivateInd(true);
        showModal();
    };
    const deactivateFacilityConfirmationDismissModal = () => {
        setDeactivateFacilityActivateInd(false);
        showModal();
    };
    const removeFacilityConfirmationPopupModal = () => {
        setRemoveFacilityActivateInd(true);
        showModal();
    };
    const removeFacilityConfirmationDismissModal = () => {
        setRemoveFacilityActivateInd(false);
        showModal();
    };
    const activateFacilityConfirmationPopupModal = () => {
        setActivateFacilityActivateInd(true);
        showModal();
    };
    const activateFacilityConfirmationDismissModal = () => {
        setActivateFacilityActivateInd(false);
        showModal();
    };

    return (
        <HospitalBannerWrapper>
            <div className="facility-details-banner" id={detailsTitleBannerId}>
                <div className="d-flex justify-content-between align-items-start hospital-banner-header">
                    <h2 className="h3 hospital-banner-title pr-4">
                        {!isCorporation && !isEmsAdmin && (
                            <span>Online Application&nbsp;-&nbsp;</span>
                        )}
                        <span id={facilityId}>{hospital.facilityName}</span>
                    </h2>
                    {isEmsAdmin && (
                        <div
                            className="dropdown facility-settings"
                            role="menubar"
                        >
                            <button
                                className="btn btn-text dropdown-toggle aha-icon-meat-balls facility-settings-icon"
                                type="button"
                                id={id}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-label="more actions"
                                role="menuitem"
                            />
                            <div className="facility-settings-list dropdown-menu py-0">
                                <ul role="menu" aria-labelledby={id} id="">
                                    {!isExport && (
                                        <li role="none">
                                            <button
                                                role="menuitem"
                                                className="btn btn-text dropdown-item"
                                                type="button"
                                                onClick={
                                                    exportFacilityDetailsData
                                                }
                                            >
                                                <i
                                                    className="aha-icon-export font-red mr-2"
                                                    aria-hidden="true"
                                                />
                                                Export
                                            </button>
                                        </li>
                                    )}
                                    {hospital && isCorporation && (
                                        <li role="none">
                                            <button
                                                role="menuitem"
                                                className="btn btn-text dropdown-item"
                                                type="button"
                                                onClick={
                                                    removeFacilityConfirmationPopupModal
                                                }
                                            >
                                                <i className="aha-icon-close font-red mr-2" />
                                                Remove
                                            </button>
                                        </li>
                                    )}
                                    {hospital &&
                                        (hospital.approvalStatus ==
                                            CONSTANTS.REGISTRED ||
                                            hospital.approvalStatus ==
                                                CONSTANTS.RENEWING) &&
                                        hospital.isActive == true &&
                                        !isCorporation && (
                                            <li role="none">
                                                <button
                                                    role="menuitem"
                                                    className="btn btn-text dropdown-item"
                                                    type="button"
                                                    onClick={
                                                        deactivateFacilityConfirmationPopupModal
                                                    }
                                                >
                                                    <i
                                                        className="aha-icon-stop font-red mr-2"
                                                        aria-hidden="true"
                                                    />
                                                    Deactivate
                                                </button>
                                            </li>
                                        )}
                                    {hospital &&
                                        hospital.approvalStatus ==
                                            CONSTANTS.PENDING &&
                                        !isCorporation &&
                                        orderId && (
                                            <li role="none">
                                                <button
                                                    role="menuitem"
                                                    className="btn btn-text dropdown-item"
                                                    type="button"
                                                    onClick={approveFacility}
                                                >
                                                    <i
                                                        className="aha-icon-group-4513 font-red"
                                                        aria-hidden="true"
                                                    />
                                                    Approve
                                                </button>
                                            </li>
                                        )}
                                    {hospital.isActive === false &&
                                        !isCorporation && (
                                            <li role="none">
                                                <button
                                                    role="menuitem"
                                                    aria-label="Activate Organization"
                                                    className="btn btn-text dropdown-item"
                                                    onClick={
                                                        activateFacilityConfirmationPopupModal
                                                    }
                                                >
                                                    <i
                                                        className="aha-icon-group-4513 font-red"
                                                        aria-hidden="true"
                                                    />
                                                    Activate
                                                </button>
                                            </li>
                                        )}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
                <div className="d-flex flex-wrap hospital-banner-detail">
                    {isEmsAdmin && (
                        <div className="hospital-banner-item">
                            <span className="abbr-code mx-1">
                                {wordSplit("AHA EMS ID:")}
                            </span>
                            <span className="hospital-banner-value font-bold">
                                {programData &&
                                    programData.emsId?.split("_")[0]}
                            </span>
                        </div>
                    )}
                    <div className="hospital-banner-item">
                        Registered Program(s):
                        <span className="hospital-banner-value font-bold">
                            {programData && programData.programCategoryName}
                        </span>
                    </div>
                    {(isCorporation &&
                        ![
                            "corporationFacilityDocuments",
                            "corporationFacilityMeasures",
                        ].includes(activeMenu)) ||
                    isAdmin ||
                    isEmsAdmin ? (
                        <div className="hospital-banner-item">
                            Organization Code:
                            <span className="hospital-banner-value font-bold abbr-code">
                                {wordSplit(hospital.facilityCode)}
                            </span>
                        </div>
                    ) : (
                        <div className="hospital-banner-item">
                            Location:
                            <span className="hospital-banner-value font-bold">
                                {hospital.countryName}
                            </span>
                        </div>
                    )}
                    {((isCorporation &&
                        ![
                            "corporationFacilityDocuments",
                            "corporationFacilityMeasures",
                        ].includes(activeMenu)) ||
                        isAdmin ||
                        isEmsAdmin) && (
                        <>
                            {hospital.isActive &&
                                certificateData?.programCertificateName && (
                                    <div className="hospital-banner-item">
                                        Award:
                                        <span className="hospital-banner-value font-bold">
                                            {
                                                certificateData?.programCertificateName
                                            }
                                        </span>
                                    </div>
                                )}
                            {hospital.isActive &&
                                certificateData?.programCertificateGrantedOn && (
                                    <div className="hospital-banner-item">
                                        Granted on:
                                        <span className="hospital-banner-value font-bold">
                                            {localDateToYYYYMMDDFormatString(
                                                certificateData?.programCertificateGrantedOn
                                            )}
                                        </span>
                                    </div>
                                )}
                            {hospital.isActive &&
                                certificateData?.programCertificateExpiryDate && (
                                    <div className="hospital-banner-item">
                                        Expires on:
                                        <span className="hospital-banner-value font-bold">
                                            {localDateToYYYYMMDDFormatString(
                                                certificateData?.programCertificateExpiryDate
                                            )}
                                        </span>
                                    </div>
                                )}
                            {programData?.approvalStatus != CONSTANTS.PENDING &&
                                programData?.verifiedBy?.firstName &&
                                programData.approvedDate && (
                                    <div className="hospital-banner-item">
                                        Approved By:
                                        <span className="hospital-banner-value font-bold">
                                            {programData.verifiedBy.firstName}{" "}
                                            {programData.verifiedBy.lastName},{" "}
                                            {localDateToYYYYMMDDFormatString(
                                                programData.approvedDate
                                            )}{" "}
                                            {moment
                                                .utc(programData.approvedDate)
                                                .local()
                                                .format("hh:mm:ss A")}
                                        </span>
                                    </div>
                                )}
                            {lastEditedInfo && (
                                <div className="hospital-banner-item">
                                    Last Edited By:
                                    <span className="hospital-banner-value font-bold">
                                        {lastEditedInfo}
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {/* Deactivate Modal */}
            {(hospital.approvalStatus == CONSTANTS.REGISTRED ||
                hospital.approvalStatus == CONSTANTS.RENEWING) &&
                hospital.isActive == true &&
                deactivateFacilityActivateInd && (
                    <FocusTrap
                        focusTrapOptions={{
                            escapeDeactivates: false,
                            clickOutsideDeactivates: false,
                        }}
                    >
                        <div
                            className="modal fade show aui-modal"
                            tabIndex={-1}
                            aria-labelledby={confirmDeactivateModalId}
                            aria-modal="true"
                            role="dialog"
                            id="deactivateConfirmationModal"
                            style={{
                                display: deactivateFacilityActivateInd
                                    ? "block"
                                    : "none",
                            }}
                        >
                            <div className="modal-dialog modal-dialog-centered modal-sm">
                                <div className="modal-content">
                                    <div className="modal-body p-0">
                                        <div className="text-center">
                                            <span
                                                id={confirmDeactivateModalId}
                                                className="sr-only"
                                            >
                                                Confirm Deactivate Organization
                                            </span>
                                            <p className="pb-2">
                                                Are you sure you want to
                                                deactivate&nbsp;
                                                <b>
                                                    {hospital.facilityName}
                                                </b>{" "}
                                                organization?
                                            </p>
                                            <div className="mt-4 d-flex justify-content-center">
                                                <button
                                                    className="btn btn-secondary btn-round btn-sm mx-2"
                                                    aria-label="Cancel Deactivation and close the modal"
                                                    onClick={
                                                        deactivateFacilityConfirmationDismissModal
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="btn btn-primary btn-round btn-sm mx-2"
                                                    onClick={deactivateFacility}
                                                    data-dismiss="modal"
                                                    aria-label="Confirm Deactivate"
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FocusTrap>
                )}
            {/* Un-map Modal */}
            {hospital && isCorporation && removeFacilityActivateInd && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                    }}
                >
                    <div
                        className="modal fade show aui-modal"
                        tabIndex={-1}
                        aria-labelledby={confirmRemoveModalId}
                        aria-modal="true"
                        role="dialog"
                        style={{
                            display: removeFacilityActivateInd
                                ? "block"
                                : "none",
                        }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    <div className="text-center">
                                        <span
                                            id={confirmRemoveModalId}
                                            className="sr-only"
                                        >
                                            Confirm Removing this Organization
                                        </span>
                                        <p className="pb-2">
                                            Are you sure you want to remove
                                            <b> {hospital.facilityName} </b>
                                            from the Corporation?
                                        </p>
                                        <div className="mt-4 d-flex justify-content-center">
                                            <button
                                                className="btn btn-secondary btn-round btn-sm mx-2"
                                                aria-label="Cancel remove and close the modal"
                                                onClick={
                                                    removeFacilityConfirmationDismissModal
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round btn-sm mx-2"
                                                onClick={unmapFacility}
                                                data-dismiss="modal"
                                                aria-label="Confirm Removing this organization"
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
            {/* Activate Modal */}
            {hospital.isActive === false &&
                isAdmin &&
                activateFacilityActivateInd && (
                    <FocusTrap
                        focusTrapOptions={{
                            escapeDeactivates: false,
                            clickOutsideDeactivates: false,
                        }}
                    >
                        <div
                            className="modal show fade aui-modal"
                            tabIndex={-1}
                            aria-labelledby="modalLabel"
                            aria-modal="true"
                            role="dialog"
                            style={{
                                display: activateFacilityActivateInd
                                    ? "block"
                                    : "none",
                            }}
                        >
                            <div className="modal-dialog modal-dialog-centered modal-sm">
                                <div className="modal-content">
                                    <div className="modal-body p-0">
                                        <div className="text-center">
                                            <span className="sr-only">
                                                Confirm Activate Organization
                                            </span>
                                            <p className="pb-2">
                                                Are you sure you want to
                                                activate&nbsp;
                                                <b>
                                                    {hospital.facilityName}
                                                </b>{" "}
                                                organization?
                                            </p>
                                            <div className="mt-4 d-flex justify-content-center">
                                                <button
                                                    className="btn btn-secondary btn-round btn-sm mx-2"
                                                    aria-label="Cancel Activation and close the modal"
                                                    onClick={
                                                        activateFacilityConfirmationDismissModal
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="btn btn-primary btn-round btn-sm mx-2"
                                                    onClick={activateFacility}
                                                    aria-label="Confirm Activate"
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FocusTrap>
                )}

            {/* Activate Modal-EMS Admin */}
            {hospital.isActive === false &&
                isEmsAdmin &&
                activateFacilityActivateInd && (
                    <FocusTrap
                        focusTrapOptions={{
                            escapeDeactivates: false,
                            clickOutsideDeactivates: false,
                        }}
                    >
                        <div
                            className="modal show fade aui-modal"
                            id="modalTemplate4"
                            tabIndex={-1}
                            aria-labelledby="modalLabel"
                            aria-modal="true"
                            role="dialog"
                            style={{
                                display: activateFacilityActivateInd
                                    ? "block"
                                    : "none",
                            }}
                        >
                            <div className="modal-dialog modal-dialog-centered modal-sm">
                                <div className="modal-content">
                                    <div className="modal-body p-0">
                                        <div className="text-center">
                                            <span
                                                id="activateModalLabel"
                                                className="sr-only"
                                            >
                                                Confirm Activate Organization
                                            </span>
                                            <p className="pb-2">
                                                Are you sure you want to
                                                activate&nbsp;
                                                <b>
                                                    {hospital.facilityName}
                                                </b>{" "}
                                                organization?
                                            </p>
                                            <div className="mt-4 d-flex justify-content-center">
                                                <button
                                                    className="btn btn-secondary btn-round btn-sm mx-2"
                                                    aria-label="Cancel Activation and close the modal"
                                                    onClick={
                                                        activateFacilityConfirmationDismissModal
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="btn btn-primary btn-round btn-sm mx-2"
                                                    onClick={activateFacility}
                                                    aria-label="Confirm Activate"
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FocusTrap>
                )}
        </HospitalBannerWrapper>
    );
};

export default FacilityDetailsBanner;
