import React, { useEffect, useState } from "react";
import Navigator from "../../components/Navigator";
import "styles/page-heading.scss";
import DashboardEmptyState from "components/DashboardEmptyState/DashboardEmptyState";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import EUPBanner from "components/EUPBanner";
import { BreadCrumb } from "components/BreadCrumb";
import { useDispatch } from "react-redux";
import DashboardCharts from "components/DashboardCharts/DashboardCharts";
import { useLocation } from "react-router";
import CONSTANTS from "common/constants";
import { getHospitalProgramCertificate } from "../../api/certificateAPI";
import { getHospitalProgramDetails } from "../../api/hospitalProgramsAPI";
import { getHospitalById } from "../../api/hospitalApi";
import { DashboardWrapper } from "./styled";
import "styles/toggle-switch.scss";

const DashboardPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [hospital, setHospital] = useState<any>({});
    const [programData, setProgramData] = useState<any>();
    const [certificateData, setCertificateData] = useState<any>();
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [isCertificateLoaded, seIsCertificateLoaded] =
        useState<boolean>(false);
    const [dashboardType, setDashboardType] = useState<any>(
        CONSTANTS.DASHBOARD_TYPE.CHART
    );
    const hospitalId: any = localStorage.getItem("selectedHospitalId");
    const programId: any = localStorage.getItem("selectedProgramId");

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            getHospitalById(hospitalId),
            getHospitalProgramDetails(programId, hospitalId),
        ])
            .then((response: any) => {
                if (response[0].success && response[1].success) {
                    setHospital(response[0].data.hospital);
                    setProgramData(response[1].data);
                    getHospitalProgramCertificate(
                        hospitalId,
                        programId,
                        response[1].data.programCategoryId
                    ).then((certResponse: any) => {
                        if (certResponse.success) {
                            setCertificateData(
                                certResponse.data.programCertficate
                            );
                            seIsCertificateLoaded(true);
                        }
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    });
                    const elementsArray = [];
                    elementsArray.push(
                        {
                            label: "Home",
                            returnPath: {
                                pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                                state: {
                                    pageNum: location?.state?.pageNum,
                                    search: location?.state?.search,
                                },
                            },
                        },
                        {
                            label: response[0].data.hospital.facilityName,
                            returnPath: "",
                        }
                    );
                    setBreadcrumbItems(elementsArray);
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, []);

    const setDashBoardType = (event: any) => {
        if (event.target.checked) {
            setDashboardType(CONSTANTS.DASHBOARD_TYPE.TABLE);
        } else {
            setDashboardType(CONSTANTS.DASHBOARD_TYPE.CHART);
        }
    };
    return (
        <DashboardWrapper>
            <Navigator tabName="dashboard" />
            <div className="container">
                <div className="mb-n2">
                    <BreadCrumb breadcrumbItems={breadcrumbItems} />
                </div>
                <div className="main-pg-heading d-flex justify-content-between align-items-center">
                    <h1 className="h3 mb-0">Dashboard</h1>
                    {programId !== "16" && (
                        <div className="view-toggle-switch mb-1">
                            <input
                                type="checkbox"
                                className="view-toggle-input"
                                onChange={setDashBoardType}
                                id="chartTableView"
                                role="button"
                                aria-pressed={
                                    dashboardType ==
                                    CONSTANTS.DASHBOARD_TYPE.TABLE
                                }
                            />
                            {dashboardType == CONSTANTS.DASHBOARD_TYPE.TABLE ? (
                                <label
                                    htmlFor="chartTableView"
                                    className="view-toggle-control"
                                >
                                    table view selected, click to change to
                                    chart view
                                </label>
                            ) : (
                                <label
                                    htmlFor="chartTableView"
                                    className="view-toggle-control"
                                >
                                    chart view selected, click to change to
                                    table view
                                </label>
                            )}
                            <span
                                className="view-toggle-slider"
                                aria-hidden="true"
                            >
                                <span
                                    className="toggle-graph-icon1 toggle-graph-icon"
                                    aria-hidden="true"
                                >
                                    Chart
                                </span>
                                <span
                                    className="toggle-graph-icon2 toggle-graph-icon"
                                    aria-hidden="true"
                                >
                                    Table
                                </span>
                            </span>
                        </div>
                    )}
                </div>
                <div className="mb-4">
                    <EUPBanner
                        hospital={hospital}
                        programData={programData}
                        certificateData={certificateData}
                    />
                </div>
                {isCertificateLoaded ? (
                    programId !== CONSTANTS.HYPERTENSION_PROGRAM_ID &&
                    programId !== CONSTANTS?.EMS_PROGRAM_ID ? (
                        <DashboardCharts
                            programCategoryId={programData.programCategoryId}
                            hospitalId={hospitalId}
                            programId={programId}
                            programName={programData.programName}
                            programData={programData}
                            hospital={hospital}
                            certificateData={certificateData}
                            dashboardType={dashboardType}
                        />
                    ) : (
                        <DashboardEmptyState />
                    )
                ) : null}
            </div>
        </DashboardWrapper>
    );
};

export default DashboardPage;
